import React, { useState } from 'react'
import './CUET.css'
import { Accordion, Button, Card, Col, Image, Row } from 'react-bootstrap'
import CUETUGForm from './CUETUGForm'
import UGTestimonials from '../Testimonial/UGTestimonial'
import wpicon from '../../../Assets/Frame_1.png'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import NewCourseForm from '../NewCourseSearch/NewCourseForm'

export default function CUETUGNewpage() {

    const [show, setShow] = useState(false);

    const navigate = useNavigate(); // Initialize the useNavigate hook

    const defaultMessage = process.env.REACT_APP_MESSAGE;

    const videos = [
        { title: "Biology", url: "https://www.youtube.com/embed/V4bczUSpoXk" },
        { title: "History", url: "https://www.youtube.com/embed/uSqdaXzgLvM" },
        { title: "English", url: "https://www.youtube.com/embed/8PE9NoNWxIQ" },
    ];

    return (
        <>

            <Helmet>
                <title>CUET(UG) 2024 & 2025: Exam Pattern, Eligibility, and Latest Updates | Complete Guide</title>
                <meta
                    name="description"
                    content="Get all details about CUET(UG) 2024 & 2025, including the exam pattern, eligibility, and key changes. Learn about the benefits of CUET(UG) for admission to top universities like DU, JNU, and BHU."
                />
                <meta property="og:title" content="CUET(UG) 2024 & 2025: Exam Pattern, Eligibility, and Latest Updates" />
                <meta property="og:description" content="CUET(UG) 2024 & 2025: Exam Pattern, Eligibility, and Latest Updates" />
            </Helmet>

            <div className="fab-wrapper" style={{ zIndex: "999" }}>
                <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
                <label className="fab d-flex align-items-center justify-content-center text-white" htmlFor="fabCheckbox">
                    {/* <span className="fab-dots fab-dots-2">
              <FaPlus />
            </span> */}
                    <a
                        className=""
                        target="_blank"
                        href={`https://wa.me/${8133836741}?text=${defaultMessage}`}
                    >
                        {/* <FaWhatsapp /> */}
                        <img src={wpicon} alt="icon" className="img-fluid" />
                    </a>

                </label>

            </div>
            {/* CAT Hero Section */}

            <div className='join_img'>
                <div className="reg_content mb-5">
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-12 d-flex flex-column text-center'>
                            <h1 className='mt-5' style={{ fontSize: '35px', color: '#fff' }}>“Your Gateway to CUET(UG) Success – Free Tests, Mock Papers & More!”</h1>

                            <p className='mt-5 fs-5'>
                                “Prepare with expert-crafted resources, real-time mock tests, and live classes.”
                            </p>
                        </div>
                        {/* <div className='col-md-12 text-center mb-5 mt-5'>
                            <a href="../BESST PROFILE.pdf" download>
                                 <FaCirclePlay size={100} className="fa-circle-play mt-5" />
                                <FaRegFilePdf size={100} className="fa-circle-play mt-5" />
                            </a>
                        </div> */}
                        <div className='col-md-12  mb-5 mt-5'>
                            <button
                                className="btn-sm btn-primary me-2"
                                type="button"
                                onClick={() => {
                                    window.location.href = "/signup";
                                }}
                            >
                                Take a Free Test
                            </button>
                            <button
                                className="btn-sm btn-secondary me-2"
                                type="button"
                                onClick={() => {
                                    window.location.href = "/subscription";
                                }}
                            >
                                Download Study Material
                            </button>
                        </div>
                    </div>
                </div>
            </div>





            {/* Content */}

            {/* <div className="col-md-12 mt-5">
                <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                    <Image
                        src={`${process.env.PUBLIC_URL}/assets/online_pg/gettyimages-692236966-612x612.jpg`}
                        className="courseimg img-fluid"
                        rounded
                        alt="CTET"
                        style={{ objectFit: 'cover', width: '600px' }}
                    />


                    <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                        <h2 className="htwo_size text-start" >  CUET(UG): Your Gateway to Top Universities</h2>


                        <div style={{ fontSize: '1rem', lineHeight: '1.6', paddingLeft: '20px' }} className="mt-3">
                            <p>CUET(UG), or the Common University Entrance Test (Undergraduate), is a national-level entrance
                                exam conducted annually by the NTA (National Testing Agency). It serves as a gateway for students
                                seeking admission to prestigious central and participating universities across India, such as Delhi
                                University (DU), JNU, BHU, Visva-Bharati, Tezpur University, and more. By taking CUET(UG),
                                students gain access to a wide range of undergraduate courses and enhance their academic and
                                career prospects. </p>
                        </div>


                    </div>
                </div>
            </div> */}

            {/* Key Feature */}

            <div className="ctet_syllabus container mt-5 mb-4">
                <div className="align_center  ml-2">
                    <div className="d-flex justify-content-center text-center">
                        <h1 style={{
                            color: 'black',
                            borderBottom: '3px solid #5a1e7d',
                            width: '50%'


                        }}>What BESST Offers</h1>
                    </div>
                    <div md={11} sm={11} xs={11}>

                        <Row className="intro-cards-container m-2">
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">

                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"
                                                fluid
                                            />
                                        </div>

                                        <div className="text-center col-md-9">
                                            <p className="user_name">Take a Free Test</p>
                                        </div>


                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/signup' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">

                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">Mock Papers</p>
                                        </div>

                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/subscription' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">
                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">	 Online Classes</p>
                                        </div>
                                        <div className='text-center'>
                                            {/* <Button className=' btn btn-sm btn-warning'>Explore More</Button> */}
                                        </div>
                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/CUET-Online-Classes' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">

                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">Free Councelling</p>
                                        </div>

                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/Free-Counselling' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>

                            <Col lg={1} md={3} sm={6} xs={6} className='p-3' >

                            </Col>

                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">

                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">Previous Year Question Paper</p>
                                        </div>

                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/signup' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">

                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">Download Study Material</p>
                                        </div>
                                        <div className='text-center'>
                                            {/* <Button className=' btn btn-sm btn-warning'>Explore More</Button> */}
                                        </div>
                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/subscription' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                                <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                                    <Row className="intro-center">
                                        <div className="col-md-3 text-center">
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                alt="frame"

                                                fluid
                                            />
                                        </div>
                                        <div className="text-center col-md-9">
                                            <p className="user_name">CSAS Portal (Delhi University) </p>
                                        </div>
                                        <div className='text-center'>
                                            {/* <Button className=' btn btn-sm btn-warning'>Explore More</Button> */}
                                        </div>
                                    </Row>
                                </div>
                                <div className='text-center' >
                                    <a href='/signup' className=' btn btn-sm btn-warning' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '-18px' }}>Explore More</a>
                                </div>
                            </Col>
                            <Col lg={2} md={3} sm={6} xs={6} className='p-3' >

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


            <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-2xl p-6 text-center space-y-6 mt-4">
                <h1 className="text-3xl font-bold text-blue-600 text-dark">CUET UG: Your Gateway to Top Universities</h1>
                <p className="text-gray-600 text-lg">
                    CUET UG is a national-level examination for students seeking admission to prestigious Central &amp; participating universities across India.
                </p>

                <div className="bg-blue-50 p-4 rounded-lg">
                    <h2 className="text-2xl font-semibold text-blue-700">Highlights of the Exam</h2>
                    <div className="text-gray-700 space-y-3 mt-4">
                        <p><span className="font-bold text-blue-600" style={{ color: "purple" }}>Full Name:<br></br></span> &gt; Common University Entrance Test (Under Graduate)</p>
                        <p><span className="font-bold text-blue-600" style={{ color: "purple" }}>Conducting Authority:<br></br></span> &gt; National Testing Agency (NTA)</p>
                        <p><span className="font-bold text-blue-600" style={{ color: "purple" }}>Mode of Exam:<br></br></span> &gt; Computer-based test (CBT)</p>
                        <p><span className="font-bold text-blue-600" style={{ color: "purple" }}>Eligibility:<br></br></span> &gt; Passed or appearing class XII</p>
                    </div>
                </div>

                <a href="https://cuet.nta.ac.in" target="_blank"
                    className="inline-block text-primary bg-blue-600 font-medium text-lg px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 transition">
                    Visit Official Website
                </a>
            </div>

            {/* Table */}

            <div className="mt-4 mb-4 p-4">
                <div className='text-center'>
                    <h2 className="htwo_size mt-4">CUET(UG) Exam Pattern</h2>
                    <p className="mt-1 mb-2">  Cuet (ug) 2025 exam is divided into four sections, each designed to
                        assess different aspects of a candidate’s knowledge and skills.</p>
                </div>
                <span className='mt-3'>Here’s a breakdown of the exam pattern: </span>

                <table className="responsive-table">
                    <tbody>
                        <tr className="text-center">
                            <td>Sections</td>
                            <td>(i)</td>
                            <td>(ii)</td>
                            <td>(iii)</td>
                        </tr>
                        <tr>
                            <td>Subjects</td>
                            <td>13 languages</td>
                            <td>23 Domain-Specific Subjects</td>
                            <td>GAT (General Aptitude Test)</td>
                        </tr>
                        <tr>
                            <td>Number of questions (all compulsory)</td>
                            <td>50</td>
                            <td>50</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td>60 minutes</td>
                            <td>60 minutes</td>
                            <td>60 minutes</td>
                        </tr>
                    </tbody>
                </table>

            </div>



            <NewCourseForm />

            {/* Content */}


            <div className=" mt-4 p-5">

                <div className="">

                    <div className='text-center'>
                        <h2 className='htwo_size '> CUET (UG) EXAM PATTERN COMPARISON (2024 & 2025)</h2>
                        <p>Here’s a detailed comparison of the CUET(UG) exam pattern for 2024 and 2025:</p>
                    </div>


                    <table className="responsive-table-2 p-5">
                        <tbody>
                            <tr className="text-center">
                                <td>Changing sections</td>
                                <td>CUET (UG) 2024</td>
                                <td>CUET (UG) 2025</td>
                            </tr>
                            <tr>
                                <td>MODE OF EXAM</td>
                                <td>HYBRID MODE (PEN & PAPER + CBT)</td>
                                <td>CBT (COMPUTER BASED TEST)</td>
                            </tr>
                            <tr>
                                <td>CHOICE OF TEST PAPER</td>
                                <td>MAXIMUM- 6</td>
                                <td>MAXIMUM- 5</td>
                            </tr>
                            <tr>
                                <td>TOTAL MARKS</td>
                                <td>200</td>
                                <td>250</td>
                            </tr>
                            <tr>
                                <td>QUESTION TO BE ATTEMPTED</td>
                                <td>SEC (I A, I B)- 40, SEC (II)- 40, SEC (III)- 50</td>
                                <td>50 (ALL ARE COMPULSORY)</td>
                            </tr>
                            <tr>
                                <td>DURATION</td>
                                <td>
                                    SEC(I A, I B)- 45 MINUTES EACH <br />
                                    SEC(II)- 45 MINUTES EXCEPT FOR MATHS/APPLIED MATHS, PHYSICS, CHEMISTRY, ECONOMICS, ACCOUNTANCY, COMPUTER SCIENCE/INFORMATICS PRACTICES (60 MINUTES) <br />
                                    SEC (III)- 60 MINUTES
                                </td>
                                <td>60 MINUTES FOR ALL TEST PAPER</td>
                            </tr>
                            <tr>
                                <td>TOTAL SUBJECT</td>
                                <td>63</td>
                                <td>37</td>
                            </tr>
                        </tbody>
                    </table>



                    <div className='p-5'>
                        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="mt-3 rounded">
                            <div className="p-3">
                                <h3 className="hthree_size mb-2" style={{ color: 'orange' }}> Note</h3>
                                These are the key changes in cuet (ug) 2025. Stay connected with us for the latest news
                                and updates on cuet (ug) 2025.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Mock Test modal */}
            <div className='join_img'>
                <div className="reg_content mb-5">
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-12 d-flex flex-column text-center'>
                            <h1 className='mt-5 fs-sm-6' style={{ color: '#fff' }}> Mock Test Registration</h1>
                        </div>

                        <div className='col-md-12  mb-5 mt-5'>
                            <button
                                className="reg_btn btn me-2"
                                type="button"
                                onClick={() => setShow(true)}
                            >
                                Enroll Now
                            </button>
                            {/* <br></br> */}
                            <button
                                className="reg_btn btn me-2"
                                type="button"
                                onClick={() => {
                                    localStorage.setItem("preferredCourseId", "1")
                                    // navigate('/subscription')
                                    window.location.href = "/signup";
                                }}
                            >
                                Registration
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            {/* Online Class schedule */}
            {/* 
            <div className='text-center gray_bg' style={{ padding: '4.5rem' }}>
            <h1 className='hone_size text-dark' >Online Class Schedule</h1>
                <div className="col-mad-12">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        initialView='dayGridMonth'
                        headerToolbar={{
                            left: "title",
                            right: 'prev next',
                        }}
                        height={600}
                        weekends={true}
                        events={[
                            { title: 'CUET UG', date: '2025-02-02' },
                            { title: 'CUET PG', date: '2025-02-05' },
                            { title: 'NCERT CLASS XII', date: '2025-02-07' },
                            { title: 'SEBA CLASS XI', date: '2025-02-09' },
                            { title: 'CTET', date: '2025-02-12' },
                        ]}
                        dayMaxEventRows={2}
                        firstDay={1}
                        eventClick={handleEventClick}  // Add the eventClick handler here
                    />
                </div>
            </div> */}


            {/* youtube link */}

            <div className="container mt-4 d-flex flex-column align-items-center text-center">
                <h2 className="mb-4 htwo_size">Demo Sessions</h2>
                <div className="row w-100 justify-content-center">
                    {videos.map((video, index) => (
                        <div key={index} className="col-md-4 mb-4 d-flex justify-content-center flex-column align-items-center">
                            <h4 className="mb-2 hfour_size" style={{ color: '#7b1fa2' }}>{video.title}</h4>
                            <div className="ratio ratio-16x9" style={{ width: '100%', maxWidth: '400px' }}>
                                <iframe
                                    className="embed-responsive-item"
                                    src={video.url}
                                    allowFullScreen
                                    title={video.title}
                                    style={{ borderRadius: '10px' }}
                                ></iframe>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Testimonial */}

            <UGTestimonials />

            {/* FAQ */}

            <div className="blog-faq w-100 mt-5">
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                    <div className='text-center'>
                        <h1 className="hone_size text-dark">FAQs on CUET UG
                        </h1>
                    </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">

                    <Accordion
                        data-responsive-accordion-tabs="-medium-tabs large-tabs"

                        className="w-100"
                    >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                How many universities are participating in CUET?
                            </Accordion.Header>
                            <Accordion.Body>Over 40 universities are currently participating in the CUET examination process.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                Which is the best college under CUET?
                            </Accordion.Header>
                            <Accordion.Body>
                                Colleges like Delhi University and Jawaharlal Nehru University are often regarded as among the
                                best under the CUET framework.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                What is a good score in CUET UG?
                            </Accordion.Header>
                            <Accordion.Body>A score around 200 out of 300 is typically considered competitive for top colleges.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                How many domain subjects are compulsory in CUET for DU?
                            </Accordion.Header>
                            <Accordion.Body>For Delhi University, candidates must choose at least two domain-specific subjects along with a
                                language paper.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                In case of a tie in the CUET score who will get the seat?
                            </Accordion.Header>
                            <Accordion.Body>If two or more students achieve the same CUET score, their Class 12 board marks will be used as a tiebreaker</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                What is the CUET (UG) syllabus?
                            </Accordion.Header>
                            <Accordion.Body>The CUET (UG) syllabus covers the entire NCERT Class 12 curriculum for the relevant subjects.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                How many courses/ universities can one apply through CUET?
                            </Accordion.Header>
                            <Accordion.Body>A candidate may apply to multiple courses and universities, provided they meet the eligibility criteria set by each university.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                Is CUET conducted once or twice a year?
                            </Accordion.Header>
                            <Accordion.Body>As of now CUET is conducted once a year.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </div>




            {/* Youtube video */}

            {/* <div className="col-md-12 mt-5">
                <div className='d-inherit d-md-flex text-center justify-content-center' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                    <iframe
                        style={{ objectFit: 'cover', width: '600px' }}
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/us4Tn_icgs4?si=Aqo6WaXmzBLXEGAG"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>

                </div>
            </div> */}


            <CUETUGForm setShow={setShow} show={show} type={"cuetugcourse"} />
        </>
    )
}
