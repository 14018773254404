import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Form, Modal, Spinner, } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Swal from "sweetalert2";
import { CUETUGSchema } from './ValidationSchema.js'
import Select from 'react-select'
import { ctetFormTodo } from '../../../Redux/Thunks/Post/PostCTETFormThunk.js';

export default function CUETUGForm(props) {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { show, setShow, type } = props
    const [spinner, setSpinner] = useState(false);

    const mock_test_option = [
        { value: '1', label: 'Mock 1' },
        { value: '2', label: 'Mock 2' },
        { value: '3', label: 'Mock 3' },
        { value: '4', label: 'Mock 4' },
    ]

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        // mock_test: "",
        type: type,
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: CUETUGSchema,

        onSubmit: (values, action) => {
            setSpinner(true)
            dispatch(ctetFormTodo({ 'values': values })).then((res) => add_res(res.payload, action))

        }
    })

    const add_res = (res, action) => {
        if (res?.status == 201) {
            action.resetForm()
            setSpinner(false)
            setShow(false)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thankyou for Enroll We will get back to you shortly',
                position: 'center',
                showConfirmButton: true,
                confirmButtonText: "Ok",
            });
        } else if (res?.status == 400) {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    }

    return (
        <>
            <Modal
                // size=""
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backdrop="static"
            // fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" style={{ color: '#5a1e7d' }}>
                        <div style={{ fontWeight: '600', fontSize: '25px' }}>
                            Enroll
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Modal.Body >
                        <div className='row'>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder={'Enter Name'}
                                    className='form_input'
                                    name={'name'}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}

                                />
                                {errors.name && touched.name ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.name}</span>) : null}
                            </div>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="email"
                                    size="sm"
                                    placeholder={'Enter Email'}
                                    className='form_input'
                                    name={'email'}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                            </div>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="number"
                                    size="sm"
                                    placeholder={'Enter Phone'}
                                    className='form_input'
                                    name={'phone'}
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.phone && touched.phone ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                            </div>

                            {/* <div className="col-md-6 mt-2 mb-2">
                                <Select
                                    isClearable={true}
                                    closeMenuOnSelect={'closeMenu'}
                                    placeholder={'Select Mock Test'}
                                    options={mock_test_option ? mock_test_option : []}
                                    className='react_select'
                                    classNamePrefix="select"
                                    name={'mock_test'}
                                    onChange={(e) => {
                                        if (e) {
                                            setFieldValue('mock_test', e.value)
                                        } else {
                                            setFieldValue('mock_test', '')
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 999999 // Adjust the value as needed
                                        })
                                    }}
                                />
                                {errors.mock_test && touched.mock_test ? (<span className="text-danger p-1" style={{ fontSize: "15px" }}>{errors.mock_test}</span>) : null}
                            </div> */}


                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button style={{ fontSize: '12px' }} type='submit' size='sm' variant="primary" className="reg_btn ms-3 mb-2 mt-2"
                            // onClick={() => setShow(false)                            }
                            disabled={spinner}
                        >Submit
                            {spinner ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='ms-1'
                                />
                                : ''
                            }</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
