import React, { useState } from 'react'
import { Accordion, Card, Col, Image, Row } from 'react-bootstrap'
import CUETUGForm from './CUETUGForm';
import wpicon from '../../../Assets/Frame_1.png'

export default function CUETOnlineClasses() {

    const [show, setShow] = useState(false);

    const defaultMessage = process.env.REACT_APP_MESSAGE;

    return (
        <div>

            <div className="fab-wrapper" style={{ zIndex: "999" }}>
                <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
                <label className="fab  d-flex align-items-center justify-content-center text-white" htmlFor="fabCheckbox">
                    {/* <span className="fab-dots fab-dots-2">
              <FaPlus />
            </span> */}
                    <a
                        className=""
                        target="_blank"
                        href={`https://wa.me/${8133836741}?text=${defaultMessage}`}
                    >
                        {/* <FaWhatsapp /> */}
                        <img src={wpicon} alt="icon" className="img-fluid" />
                    </a>

                </label>

            </div>

            <div className="col-md-12 mt-5">
                <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                    <div className="cuetctn ms-0 me-0 me-sm-5 mb-5 ms-lg-5 mb-l">
                        <h1 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='hone_size'> Crack CUET(UG) with Expert Online Coaching!</h1>
                        <p style={{ textAlign: "justify", fontSize: '20px' }} className='mt-2 mb-2'>
                            Prepare for CUET(UG) with expert coaching and real exam-like practice. Master every section and boost your chances of admission—start your journey today!
                        </p>
                    </div>

                    <Image
                        src={`${process.env.PUBLIC_URL}/assets/online_pg/group-young-indian-students-sitting-auditorium-lecture-row-smiling_538002-3755.jpg`}
                        className="courseimg img-fluid"
                        rounded
                        alt="CTET"
                        style={{ objectFit: 'cover', width: '600px', height: 'auto' }}
                    />

                </div>
            </div>


            <div className="container mt-4">

                <Row className="gs-5 ge-5">
                    <Col md={12} className='text-center mt-4 mb-4'>
                        <h2 className='htwo_size '> Why Choose Our CUET Online Coaching?</h2>

                    </Col>

                    <Col md={4} >
                        <Card className="custom-card text-center h-100 ">
                            <div className="red-banner">
                                <h5 className="hfive_size" >Live Interactive Classes </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text >
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Learn from top educators in real-time with engaging sessions and in-depth explanations. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} >
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">

                                <h5 className="hfive_size" >	Free Counselling </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Get personalized guidance on your CUET preparation journey to ensure you're on the right path</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" > Downloadable Study Materials </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Access well-structured notes, concept explanations, and reference guides anytime. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} className='mt-2'                   ></Col>
                    <Col md={4} className='mt-2'>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" >Practice Papers & Mock Tests </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Test your knowledge with exam-like questions and improve your speed & accuracy. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className='mt-2'>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" > Previous Year Question Papers </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Analyze trends, understand exam patterns, and boost your confidence with past papers. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} className='mt-2'
                    ></Col>
                </Row>
            </div>



            <div fluid className=" mt-5 we-offer">
                <div className="offering_container">


                    <div className="row row-flex">
                        <div className="card-container">

                            <Card className="p-2 Ctet_Course_card match_height mb-3" style={{ border: '1px solid', overflow: 'visible' }}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/online_pg/Five-Ways-to-Engage-Students-in-an-Online-Learning-Environment.jpg`}
                                    alt="frame"
                                    className="img-course mt-0"
                                    fluid
                                    style={{ objectFit: 'cover', height: '500px' }}
                                />
                                <Card.Body>
                                    <div className="p-2">
                                        <h2
                                            className="card-university-title htwo_size"
                                            style={{ color: "#71269C" }}
                                        >
                                            Join Our High-Success CUET Program
                                        </h2>
                                        <p style={{ textAlign: "justify", fontSize: '1rem' }} className="card-university-title mt-3">With a 92%+ Success Rate, our students have secured admissions in prestigious universities across the country. Our structured curriculum, expert mentoring, and proven strategies ensure you stay ahead in the competition. Be the next success story!</p>
                                    </div>
                                </Card.Body>
                            </Card>


                            <Card className="p-2 Ctet_Course_card match_height mb-3" style={{ border: '1px solid', overflow: 'visible' }}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/online_pg/advantage online learning.jpg`}
                                    alt="frame"
                                    className="img-course mt-0"
                                    fluid
                                    style={{ objectFit: 'cover', height: '500px' }}
                                />
                                <Card.Body>
                                    <div className="p-2">
                                        <h2
                                            className="card-university-title htwo_size"
                                            style={{ color: "#71269C" }}
                                        >
                                            Get Started Today!
                                        </h2>
                                        <p style={{ textAlign: "justify", fontSize: '1rem' }} className="card-university-title mt-3">Take charge of your future—fill in your details & take the first step toward acing CUET(UG). Don’t miss this opportunity to turn your dreams into reality!</p>
                                    </div>
                                </Card.Body>
                            </Card>

                        </div>
                    </div>
                </div>
            </div>
            <div className='join_img'>
                <div className="reg_content mb-5">
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-12 d-flex flex-column text-center'>
                            <h1 className='mt-5 fs-sm-6' style={{ color: '#fff' }}> Online Class Registration</h1>
                        </div>

                        <div className='col-md-12  mb-5 mt-5'>
                            <button
                                className="reg_btn btn me-2"
                                type="button"
                                onClick={() => setShow(true)}
                            >
                                Enroll Now
                            </button>
                            <button
                                className="reg_btn btn me-2"
                                type="button"
                                onClick={() => window.location.href = "/signup"}
                            >
                                Registration
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-faq w-100 mt-5">
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                    <div className='text-center'>
                        <h1 className="hone_size text-dark">Frequently Asked Questions (FAQs)                        </h1>
                    </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">

                    <Accordion
                        data-responsive-accordion-tabs="-medium-tabs large-tabs"

                        className="w-100"
                    >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                1. What is the CUET(UG) exam, and why is it important?
                            </Accordion.Header>
                            <Accordion.Body>CUET(UG) is a national-level entrance exam for admission to top universities in India. Scoring well increases your chances of securing a seat in your desired course and college.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                2. How will your CUET(UG) online coaching help me?
                            </Accordion.Header>
                            <Accordion.Body>
                                Our coaching provides live interactive classes, expert guidance, mock tests, and study materials to help you prepare effectively and improve your exam performance.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                3. Do you offer CUET(UG) mock tests, and how are they structured?
                            </Accordion.Header>
                            <Accordion.Body>Yes, we provide full-length CUET(UG) mock tests designed to simulate the actual exam, helping you assess your readiness and identify areas for improvement.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                4. Can I access recorded sessions if I miss a live class?
                            </Accordion.Header>
                            <Accordion.Body>Absolutely! All live classes are recorded and made available, so you can revisit them anytime at your convenience.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                5.  How do I enroll in your CUET(UG) coaching program?
                            </Accordion.Header>
                            <Accordion.Body>Simply fill out the form on our website, and our team will guide you through the enrollment process to kickstart your CUET(UG) preparation!</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </div>

            <CUETUGForm setShow={setShow} show={show} type={"CUET-Online-Classes"} />
        </div>
    )
}
