import { Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import React, { useState } from "react";
import './Testimonial.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LuArrowUpRightSquare } from "react-icons/lu";


function UGTestimonials() {

    const isSmallScreen = window.innerWidth <= 500;

    const [Testinomials, setTestinoMials] = useState([

        {
            "type": 1,
            "imageURL": "tonmoy.png",
            "mediType": 1,
            "name": "Tonmoy Kashyap",
            "link": "page link",
            "content": ` During my CUET exam preparations, I took live classes on
        General Test papers provided by www.besst.in.I also practised
        their mock tests.`
        },
        {
            "type": 1,
            "imageURL": "rukh.png",
            "name": "Rukh Acharya",
            "mediType": 1,
            "link": "page link",
            "content": `  I'm glad that I got to learn about CUET and its fundamentals
        from BESST. It helped me get into a college in Noida. I'm
        thankful to you all`
        },
        {
            "type": 1,
            "imageURL": "gunjan.svg",
            "name": "Parashmoni Boruah",
            "mediType": 1,
            "link": "page link",
            "content": ` I have taken online classes with Kaushik Sir; he was the perfect
        trainer for me I could have imagined. He has been very patient
        with me and always very supportive. He starts from basics and
        gradually gets into advanced topics. The study material that sir
        provided was very useful; students with any level of knowledge
        can start, learn, and excel. Its practice papers and mock test
        papers helped me a lot, and the materials of Cuet and UG are
        very informative. Thank you sir for your support 😎`
        },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Chumi",
            "link": "page link",
            "content": "This web portal is very much useful for any kind of competitive exam preparation.. Specially for cuet.. And also provides live classes...mock tests are also available.. There are lots of questions for every subject for regular practice.. Thank you BESST for providing best materials."
        },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Krishna Maheshwari",
            "link": "page link",
            "content": "The material provided is quite helpful.A great initiative.Wishing more success and growth to BESST!"
        }
    ]);


    const responsive = {
        5000: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        1000: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        600: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        0: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const getContentOnSize = (data) => {
        return data.length > 250 ? data.substring(0, 250) + "..." : data;
    }

    return (
        <div className="">
            <section className="testimonial-area">
                <div className="testimoniyal_container" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="">
                        <h3 className="title-success">Our Success Stories</h3>
                    </div>
                    <OwlCarousel
                        className="testimonial-content owl-carousel"
                        margin={50}
                        smartSpeed={800}
                        autoplay={false}
                        autoplayTimeout={4000}
                        dots={true}
                        nav={false}
                        responsive={responsive}
                    >
                        {Testinomials?.map((item, index) => (
                            <div className="single-testimonial match_height" key={index}>
                                <div className="container-fluid mt-5 pt-5" style={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {item.mediType == 2 ?
                                        (
                                            <div >
                                                <video
                                                    className="intro-video mb-3"
                                                    src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.videoURL}`}
                                                    type="video/mp4"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    controls
                                                ></video>

                                            </div>
                                        ) : (
                                            <p className="text_para text-paragraph">{isSmallScreen ? getContentOnSize(item.content) : item.content}</p>
                                        )}
                                </div>
                                {/*  */}
                                <div className="client-info">
                                    <div className="client-video mt-5 pt-5 mb-3 me-3">
                                        {item.imageURL ?
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.imageURL}`}
                                                alt="frame"
                                                className="Caor_img"

                                                style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                            :
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`}
                                                alt="frame"
                                                className="Caor_img"

                                                style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        }

                                    </div>
                                    <div className="client-details ms-3">
                                        <h6>{item.name}</h6>
                                        <Button type="button" className="caro_btn w-100" size="sm" href={item.type == 2 ? "/testimonials" : "/success-stories"}>
                                            View Full Story

                                            <LuArrowUpRightSquare className="ms-2" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </section>

        </div>

    );
}
export default UGTestimonials;
