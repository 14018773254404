import React, { useEffect, useState } from 'react'
import { Col, Container, Form, ListGroup, Row } from 'react-bootstrap'
import { CiLocationOn } from "react-icons/ci";
import { LuDot } from 'react-icons/lu';
import { LiaUniversitySolid } from "react-icons/lia";
import { courseSearchTodo } from '../../../Redux/Thunks/Post/PostCouresSearchThunk';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { PropagateLoader } from 'react-spinners'
import Select from 'react-select'
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

export default function NewCourseForm() {

  const dispatch = useDispatch()
  const [courseDetails, setCourseDetails] = useState([]);
  const [loading, setLoading] = useState(false)
  const [courseLength, setCourseLength] = useState(false)
  // console.log(courseDetails, 'details')

  const degree = useSelector(state => state?.postResp?.courseSearch?.data?.Degree)
  const college = useSelector(state => state?.postResp?.courseSearch?.data?.College)
  const course = useSelector(state => state?.postResp?.courseSearch?.data?.Programs)
  const university = useSelector(state => state?.postResp?.courseSearch?.data?.University)

  // console.log(degree, 'degree')

  const degree_option = degree?.map(val => (
    { "value": val.id, "label": val.value }
  ))
  const college_option = college?.map(val => (
    { "value": val.id, "label": val.value }
  ))
  const course_option = course?.map(val => (
    { "value": val.id, "label": val.value }
  ))
  const university_option = university?.map(val => (
    { "value": val.id, "label": val.value }
  ))

  const initialValues = {
    degree: '',
    college: '',
    course: '',
    university: ''
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({

    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: (values, action) => {
      // console.log("values", values)
      getResult(false)

    },
  })

  const handleChangeId = (name, id) => {
    if (name == 'degree') {
      dispatch(courseSearchTodo({ "course": values.course?.value, "degree": id, "college": values.college?.value, "university": values.university?.value }))
    }
    if (name == 'course') {
      dispatch(courseSearchTodo({ "course": id, "degree": values.degree?.value, "college": values.college?.value, "university": values.university?.value }))
    }
    if (name == 'college') {
      dispatch(courseSearchTodo({ "course": values.course?.value, "degree": values.degree?.value, "college": id, "university": values.university?.value }))
    }
    if (name == 'university') {
      dispatch(courseSearchTodo({ "course": values.course?.value, "degree": values.degree?.value, "college": values.college?.value, "university": id }))
    }
  }

  const getResult = async (bool) => {
    // console.log("SearchValues", SearchValues);
    setLoading(true)
    var searchBy = {};
    if (values.course) {
      searchBy.course = { id: bool ? '' : values.course?.value };
    }
    if (values.college) {
      searchBy.college = { id: bool ? '' : values.college?.value };
    }
    if (values.degree) {
      searchBy.degree = { id: bool ? '' : values.degree?.value };
    }
    if (values.university) {
      searchBy.university = { id: bool ? '' : values.university?.value };
    }
    // /besst-0.0.1-SNAPSHOT/search
    // http://68.178.172.171:8282/besst-0.0.1-SNAPSHOT/search
    await axios
      .post(process.env.REACT_APP_SNAPSHOT_BASE_URL + `besst-0.0.1-SNAPSHOT/search`, searchBy)
      .then((response) => {
        // console.log("Search Data", response.data);
        setCourseDetails(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        setCourseDetails([]);
        setLoading(false)
        // console.log("Get Courses Error", error);
      });
  };

  const handleClear = () => {
    setFieldValue('degree', '');
    setFieldValue('university', '');
    setFieldValue('college', '');
    setFieldValue('course', '');
    dispatch(courseSearchTodo({ "course": '', "degree": '', "college": '', "university": '' }))
    getResult(true)
    setCourseLength(false)
  }

  useEffect(() => {
    dispatch(courseSearchTodo({ "course": '', "degree": '', "college": '', "university": '' }))
  }, [])

  return (
    <>
      <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>

        <Container fluid className="padding_b_15px" id="cour_form">

          <Col md={12} sm={12} xs={12} className="align_center">
            <h4 className="Course-tag">Search For CUET(UG) College/University:</h4>
            {/* search filters */}
            <Row className="Course-form">
              <Col md={6} sm={12} xs={12} className="my-4">
                <h6 className="cor--head">Degree</h6>
                <Select
                  isClearable={true}
                  closeMenuOnSelect={'closeMenu'}
                  placeholder={'Enter or Select Course'}
                  options={degree_option ? degree_option : []}
                  className='react_select'
                  classNamePrefix="select"
                  value={values.degree}
                  name={'name'}
                  onChange={(e) => {
                    if (e) {
                      setFieldValue('degree', e);
                      handleChangeId('degree', e?.value)
                    } else {
                      setFieldValue('degree', '');
                      handleChangeId('degree', '')
                    }
                  }}
                  onBlur={handleBlur}
                />
              </Col>

              <Col md={6} sm={12} xs={12} className="my-4">
                <h6 className="cor--head">University</h6>
                <Select
                  closeMenuOnSelect={'closeMenu'}
                  placeholder={'Enter or Select University'}
                  options={university_option ? university_option : []}
                  className='react_select'
                  classNamePrefix="select"
                  isClearable={true}
                  value={values.university}
                  name={'name'}
                  onChange={(e) => {
                    if (e) {
                      setFieldValue('university', e);
                      handleChangeId('university', e.value)
                    } else {
                      setFieldValue('university', '');
                      handleChangeId('university', '')
                    }
                  }
                  }
                  onBlur={handleBlur}
                />
              </Col>

              {/* <Col md={4} sm={12} xs={12} className="my-4">
                <h6 className="cor--head">College</h6>
                <Select
                  closeMenuOnSelect={'closeMenu'}
                  placeholder={'Enter or Select College'}
                  options={college_option ? college_option : []}
                  className='react_select'
                  classNamePrefix="select"
                  isClearable={true}
                  value={values.college}
                  name={'name'}
                  onChange={(e) => {
                    if (e) {
                      setFieldValue('college', e);
                      handleChangeId('college', e.value)
                    } else {
                      setFieldValue('college', '');
                      handleChangeId('college', '')
                    }
                  }}
                  onBlur={handleBlur}
                />
              </Col> */}

              <Col md={6} sm={12} xs={12} className="my-4">
                <h6 className="cor--head"> Programme/course</h6>
                <Select
                  closeMenuOnSelect={'closeMenu'}
                  placeholder={'Enter Or Select Programme/course'}
                  options={course_option ? course_option : []}
                  className='react_select'
                  classNamePrefix="select"
                  isClearable={true}
                  value={values.course}
                  name={'name'}
                  onChange={(e) => {
                    if (e) {
                      setFieldValue('course', e);
                      handleChangeId('course', e.value)
                    } else {
                      setFieldValue('course', '');
                      handleChangeId('course', '')
                    }
                  }}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={1} sm={6} xs={6} className='mt-2 ' >
                <button
                  type='submit'
                  className="button-cor"
                  style={{ marginLeft: "0", alignItems: "center" }}
                  onClick={() => setCourseLength(true)}
                >
                  Search
                </button>
              </Col>
              <Col md={1} sm={6} xs={6} className='mt-2' >
                <button className='button-cor' style={{ marginLeft: "0", alignItems: "center" }} onClick={handleClear}>
                  Clear
                </button>
              </Col>
              <Col md={4}></Col>
            </Row>
          </Col>

          {loading ?
            <div className="col-md-12">
              <div className="layout">
                <div className="text-center mb-3">
                  <PropagateLoader
                    color="#21263c"
                    size={20}
                  />
                </div>
              </div>
            </div>
            :
            <>
              {courseDetails.length > 0 ?
                (<p style={{ borderRadius: '5px', display: 'flex', justifyContent: 'center' }} className="Search_head mt-5">
                  Search Results
                  <span className="Search_head_dark">
                    “ {courseDetails.length} Colleges ”
                  </span>
                  Found , try again with different combinations.
                </p>) : ''
              }

              {courseDetails.length <= 0 ?
                courseLength ?
                  <p style={{ borderRadius: '5px', display: 'flex', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className='m-3 p-4'>No Record Found</p>
                  : ''
                :

                // {/* Data Card */}
                <div div className='mt-5'>
                  <div className="course-result-container my-3">
                    {courseDetails.map((each) => (
                      <div className="course-result-card mt-3" >

                        <div className="course-result-titles">
                          {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/WhatsApp_Image_2023-12-27_at_10.34 10.png`}
                  alt="Image Alt Text"
                  className="top-right-image"
                /> */}
                          <div className="courses-info">
                            <div className="college-location">
                              <h3 className="course-result-college-name">{each.college}</h3>
                              <CiLocationOn color='#7b1fa2' className='ms-2' />
                              {each.city}
                              {/* Delhi */}
                              <LuDot size={22} />
                              <span>
                                {each.govtStatus}
                                {/* Government */}
                              </span>

                            </div>

                            <div className="college-location">
                              <LiaUniversitySolid color='#7b1fa2' size={20} />
                              <span>
                                {each.university.name}
                                {/* University of Delhi */}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* Second card */}
                        <div className='row'>
                          <div className='col-md-12'>
                            {each?.courses?.length <= 0 ? (
                              <p
                                style={{ borderRadius: '5px', display: 'flex', justifyContent: 'center' }}
                                className='m-3 p-4'
                              >
                                No Course Found
                              </p>
                            ) : (
                              <PerfectScrollbar style={{ height: '36vh' }} >

                                {
                                  each.courses.map((item, index) => (
                                    <ListGroup key={index} className=''>
                                      <ListGroup.Item className='p-3' style={{}}>
                                        <div className='mb-2'>
                                          <h3>{item.degree}</h3>
                                        </div>
                                        <span style={{ color: "#7B1FA2" }}>Mandatory subjects CUET(UG): </span>
                                        {item.subjects}
                                        <br />
                                        <span style={{ color: "#7B1FA2" }}>Eligibility: </span>
                                        {item.eligibility}
                                      </ListGroup.Item>
                                    </ListGroup>
                                  ))
                                }
                              </PerfectScrollbar>
                            )}
                          </div>
                        </div>

                      </div>
                    ))}

                    {/* <small style={{ padding: "0.5em 1em", color: 'black' }}><strong style={{ color: 'black' }}>Disclaimer:</strong> Students are requested to verify the data/eligibility/courses from the official website of respective College/University.</small> */}
                  </div>
                </div>
              }

              {courseDetails.length > 0 ?
                <small style={{ padding: "0.5em 1em", color: 'black' }}><strong style={{ color: 'black' }}>Disclaimer:</strong> Students are requested to verify the data/eligibility/courses from the official website of respective College/University.</small>
                : ''}
            </>
          }

        </Container>
      </Form >

    </>
  )
}
