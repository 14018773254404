import React, { useState } from "react";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function CUETHeader() {
    const [colorChange, setColorchange] = useState(false);
    // const changeNavbarColor = () => {
    //   if (window.scrollY >= 50) {
    //     setColorchange(true);
    //   } else {
    //     setColorchange(false);
    //   }
    // };
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (window.scrollY >= 50) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
        const checkLoginStatus = () => {
            setIsLoggedIn(Cookies.get("token") !== undefined);
        };

        checkLoginStatus();
    }, []);

    // window.addEventListener("scroll", changeNavbarColor);
    const isHomePage = location.pathname === "/";

    return (
        <Navbar
            className={colorChange && !isHomePage ? "navbar colorChange" : "navbar"}
            expand="lg"
            style={{ backgroundColor: colorChange ? "#FFF" : "#FFFFFF01" }}
        >
            <Container className="d-flex justify-content-start align-items-center">
                <Link to="/">
                    <Navbar.Brand>
                        <img
                            alt=""
                            src={`${process.env.PUBLIC_URL}/assets/logo/7ac1037e455cb7559800318b98569c40.png`}
                            width="100"
                            height="100"
                            flex-shrink="0"
                            className="d-inline-block align-top mx-3"
                        />{" "}
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle
                    aria-controls="navbarScroll"
                    style={{ width: "fit-content", marginRight: "10px", border: "none" }}
                />
                {/* <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="ms-auto my-2 my-lg-0 pl-4"
                        // style={{ maxHeight: "100px" }}
                        navbarScroll
                    >
                 
                        <NavDropdown
                            className="menu-itm"
                            title="Courses"
                            id="navbarScrollingDropdown"
                        >
                            <NavDropdown.Item href="/cuetugcourse" style={{ fontWeight: 600, backgroundColor: "white", color: "#7b1fa2", textShadow: ' 10px 10px 20px #7b1fa2' }}>
                                CUET(UG)
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/cuetexamcourse" style={{ backgroundColor: "white", color: "black" }}>
                                CUET(PG)
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/class12" style={{ backgroundColor: "white", color: "black" }}>
                                NCERT CLASS XII
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/class10" style={{ backgroundColor: "white", color: "black" }}>
                                SEBA CLASS X
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/best-ctet-coaching-in-delhi" style={{ backgroundColor: "white", color: "black" }}>
                                CTET
                            </NavDropdown.Item>
                        </NavDropdown>


                    </Nav>

                    <Form className="d-flex justify-content-center">
                        <InputGroup style={{ maxWidth: "400px" }}>
                            <InputGroup.Text style={{
                                border: "1px solid #71269C",
                                color: "#7B1FA2",
                                borderRadius: "4px 0px 0 4px",
                                // boxSizing: "border-box",
                                padding: "7px 7px",
                                backgroundColor: "white",
                                width: "fit-content",
                                gap: "8px",
                                // textAlign: "center",
                                marginLeft: "10px"
                            }}>
                                <FaSearch color="#7b1fa2" size={20} />
                            </InputGroup.Text>
                            <FormControl
                                type="search"
                                placeholder="Search..."
                                className="shadow-sm"
                                style={{
                                    borderLeft: "none",
                                    borderColor: "#7b1fa2",
                                    borderWidth: "1px",
                                    borderRadius: "0 5px 5px 0",
                                    height: '42px'
                                }}
                            />
                        </InputGroup>
                    </Form>

                    <Nav.Link
                        className="menu-itm1"
                        href={isLoggedIn ? "/student-dashboard" : "/signin"}
                        style={{
                            border: "1px solid #71269C",
                            color: "#7B1FA2",
                            borderRadius: "4px",
                            boxSizing: "border-box",
                            padding: "7px 7px",
                            backgroundColor: "white",
                            width: "fit-content",
                            gap: "8px",
                            textAlign: "center",
                            marginLeft: "10px",
                            fontSize: '15px'
                        }}
                    >
                        {isLoggedIn ? "Dashboard" : "Sign In / Sign Up"}
                    </Nav.Link>
                </Navbar.Collapse> */}


            </Container>
        </Navbar>
    );
}

export default CUETHeader;
