import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Form, Modal, Spinner, } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ctetFormTodo } from '../../../Redux/Thunks/Post/PostCTETFormThunk'
import Swal from "sweetalert2";
import { FormSchema } from './ValidationSchema.js'

export default function FormModel(props) {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { show, setShow } = props
    const [spinner, setSpinner] = useState(false);

    const initialValues = {
        name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
        type: "best-ctet-coaching-in-delhi"
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: FormSchema,

        onSubmit: (values, action) => {
            // console.log(values)

            dispatch(ctetFormTodo({ 'values': values })).then((res) => add_res(res.payload, action))
            setSpinner(true)
        }
    })

    const add_res = (res, action) => {
        if (res?.status == 201) {
            setSpinner(false)
            action.resetForm()
            setShow(false)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thankyou for Enroll We will get back to you shortly',
                position: 'center',
                showConfirmButton: true,
                confirmButtonText: "Ok",
            });
        } else if (res?.status == 400) {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    }

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backdrop="static" 
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" style={{ color: '#5a1e7d' }}>
                        <div style={{ fontWeight: '600', fontSize: '25px' }}>
                            Lead Form
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Modal.Body>
                        <div className='row'>

                            <div className="col-md-6 mt-2 mb-2">
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder={'Enter First Name'}
                                    className='form_input'
                                    name={'name'}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}

                                />
                                {errors.name && touched.name ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.name}</span>) : null}
                            </div>

                            <div className="col-md-6 mt-2 mb-2">
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder={'Enter Last Name'}
                                    className='form_input'
                                    name={'last_name'}
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.last_name && touched.last_name ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.last_name}</span>) : null}
                            </div>

                            <div className="col-md-6 mt-2 mb-2">
                                <Form.Control
                                    type="email"
                                    size="sm"
                                    placeholder={'Enter Email'}
                                    className='form_input'
                                    name={'email'}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                            </div>

                            <div className="col-md-6 mt-2 mb-2">
                                <Form.Control
                                    type="number"
                                    size="sm"
                                    placeholder={'Enter Phone'}
                                    className='form_input'
                                    name={'phone'}
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.phone && touched.phone ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                            </div>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    placeholder={'Enter Message'}
                                    className='form_input'
                                    name={'message'}
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.message && touched.message ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.message}</span>) : null}
                            </div>

                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button style={{ fontSize: '12px' }} type='submit' size='sm' variant="primary" className="reg_btn ms-3 mb-2 mt-2"  disabled={spinner} >Submit

                            {spinner ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='ms-1'
                                />
                                : ''
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
