import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Container, Form, Navbar, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import './CUETPG.css'
import { Accordion, Card, Col, Image, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch } from 'react-redux'
import { adsPageTodo } from '../../../../Redux/Thunks/Post/PostAdsPageThunk'
import Swal from 'sweetalert2'
import { CUETPGSchema } from '../ValidationSchema'

export default function AdsPageMockTest() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [bool, setBool] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        type: "cuet-ug-mock-test"
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: CUETPGSchema,

        onSubmit: (values, action) => {
            // console.log(values)
            setSpinner(true)
            dispatch(adsPageTodo({ 'values': values })).then((res) => add_res(res.payload, action))

        }
    })

    const add_res = (res, action) => {
        if (res?.status == 201) {
            action.resetForm();
            setSpinner(false)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thank you for Enrolling. We will get back to you shortly.',
                position: 'center',
                showConfirmButton: true,
                // timer: 1500,
                confirmButtonText: "Ok",
            });
            setBool(true)

        } else if (res?.status == 400) {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            setSpinner(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    };

    const Testimonial = [

        {
            "type": 1,
            "imageURL": "tonmoy.png",
            "mediType": 1,
            "name": "Tonmoy Kashyap",
            "link": "page link",
            "content": ` During my CUET exam preparations, I took live classes on
          General Test papers provided by www.besst.in.I also practised
          their mock tests.`
        },
        {
            "type": 1,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Vijay Jain",
            "link": "page link",
            "content": `Huge thanks to Besst for helping me crack the CUET 2024 and get into Delhi University! The DU expert faculty were supportive and made learning enjoyable. Their tips and study materials were spot on. Totally worth it!`
        },
        {
            "type": 1,
            "imageURL": "rukh.png",
            "name": "Rukh Acharya",
            "mediType": 1,
            "link": "page link",
            "content": `  I'm glad that I got to learn about CUET and its fundamentals
          from BESST. It helped me get into a college in Noida. I'm
          thankful to you all`
        },
        // {
        //     "type": 1,
        //     "imageURL": "gunjan.svg",
        //     "name": "Parashmoni Boruah",
        //     "mediType": 1,
        //     "link": "page link",
        //     "content": ` I have taken online classes with Kaushik Sir; he was the perfect
        //   trainer for me I could have imagined. He has been very patient
        //   with me and always very supportive. He starts from basics and
        //   gradually gets into advanced topics. The study material that sir
        //   provided was very useful; students with any level of knowledge
        //   can start, learn, and excel. Its practice papers and mock test
        //   papers helped me a lot, and the materials of Cuet and PG are
        //   very informative. Thank you sir for your support 😎`
        // },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Chumi",
            "link": "page link",
            "content": "This web portal is very much useful for any kind of competitive exam preparation.. Specially for cuet.. And also provides live classes...mock tests are also available.. There are lots of questions for every subject for regular practice.. Thank you BESST for providing best materials."
        },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Krishna Maheshwari",
            "link": "page link",
            "content": "The material provided is quite helpful.A great initiative.Wishing more success and growth to BESST!"
        }
    ];

    const responsive = {
        5000: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        1000: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        600: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        0: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <>
            <Navbar expand="lg">
                <Container className="d-flex">
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                alt=""
                                src={`${process.env.PUBLIC_URL}/assets/logo/7ac1037e455cb7559800318b98569c40.png`}
                                width="100"
                                height="100"
                                flex-shrink="0"
                                className="d-inline-block align-top mx-3"
                            />
                        </Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>

            <Helmet>
                <title>Ace CUET (UG) with Our Premium CUET(UG) Mock Test!</title>
                <meta
                    name="description"
                    content="Experience the Real Exam Before the Big Day"
                />
                <meta property="og:title" content="Ace CUET (UG) with Our Premium CUET(UG) Mock Test!" />
                <meta property="og:description" content="Ace CUET (UG) with Our Premium CUET(UG) Mock Test!" />
            </Helmet>


            {/* CAT Hero Section */}


            <div className='ads_img mb-3'>
                {/* <div className=" "> */}
                <div className='row mb-2'>
                    <div className='col-md-7 text-center banner-text' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <h1 style={{ fontSize: '45px', textAlign: 'center', color: 'white' }} className='mt-5 ms-3'> Ace <span style={{ color: '#fcc705' }}>
                            CUET (UG)</span> with Our  <span style={{ color: '#fcc705' }}><br></br>
                                Premium </span> CUET (UG) Mock Test!

                        </h1>
                        <p className='text-center p-5' style={{ color: 'white', fontSize: '25px' }}>Are you ready to crack CUET (UG) and secure admission to top universities? Take our full-length CUET (UG) Mock Test, designed to replicate the actual exam, and assess your preparation.</p>

                    </div>
                    <div className=' col-md-5 ps-5 pe-5' style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} style={{ marginBottom: '-100px' }}>
                            <div className='w-100' style={{ backgroundColor: '#fcc705', padding: '30px 30px 30px 30px ', borderRadius: '10px' }}>
                                <h1 className='hone_size text-dark mb-5'>Fill out the form to know more</h1>
                                <div className="w-100 mt-1">
                                    <Form.Label className=' text-dark text-start d-flex mt-2 mb-2 fs-5'>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        // size="sm"
                                        placeholder={'Enter Name'}
                                        className='form_input fs-5'
                                        name={'name'}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />
                                    {errors.name && touched.name ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.name}</span>) : null}
                                </div>


                                <div className="w-100">
                                    <Form.Label className='text-dark text-start d-flex mt-5 mb-2 fs-5'>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        // size="sm"
                                        placeholder={'Enter Email'}
                                        className='form_input fs-5'
                                        name={'email'}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                                </div>

                                <div className="w-100">
                                    <Form.Label className=' text-dark text-start d-flex mt-5 mb-2 fs-5'>Mobile No.</Form.Label>
                                    <Form.Control
                                        type="number"
                                        // size="sm"
                                        placeholder={'Enter Phone'}
                                        className='form_input fs-5'
                                        name={'phone'}
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.phone && touched.phone ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                                </div>

                                <div className='col-md-12 mt-5 mb-2 text-start' style={{}}>

                                    <Button style={{ fontSize: '20px' }} type='submit' size='sm' variant="primary" className="reg_btn mb-2 mt-2 w-100 button_loading d-flex align-items-center justify-content-center" disabled={spinner} >
                                        Submit
                                        {spinner ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='ms-1'
                                            />
                                            : ''
                                        }
                                    </Button>
                                </div>
                            </div>
                        </Form >
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className="row">
                <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                    <div className='col-md-7 col-sm-12 form_query_content' >
                        <h2 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='htwo_size mb-5'> Why Take Our CUET (UG) Mock Test?</h2>
                        <div className="cuetctn">
                            <ul style={{ textAlign: "justify", fontSize: '20px', listStyleType: 'disclosure-closed' }} className='mt-2 mb-2'>
                                <li className='mb-3 mt-5 ms-5'>
                                    Exam-Like Experience – Feel the pressure of the actual test before the real day.
                                </li>
                                <li className='mb-3 mt-3 ms-5'>
                                    Detailed Performance Report – Get insights on where you stand.
                                </li>
                                <li className='mb-3 mt-3 ms-5'>
                                    Time Management Practice – Learn to complete the test within the given time.
                                </li>
                                <li className='mb-3 mt-3 ms-5'>
                                    Expert-Curated Questions – Designed by experienced faculty to match CUET (UG) standards.
                                </li>
                                <li className='mb-3 mt-3 ms-5'>
                                    Boost Your Confidence – Walk into the CUET (UG) exam fully prepared.
                                </li>
                                <p style={{ fontSize: '20px', color: 'purple' }}>
                                    🚀 Thousands of students have trusted BESST to ace CUET (UG). Now it's your turn! 🚀
                                </p>
                            </ul>
                        </div>
                    </div>

                    <div className='col-md-5 col-sm-12 form_query'>

                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators justify-content-center dot_style" >
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/assets/cuetimg/bscdegreecourse-1647858929.jpg`}
                                        className="courseimg img-fluid d-block w-100"
                                        rounded
                                        alt="CTET"
                                        style={{ height: '40vh', objectFit: 'cover' }}
                                    />
                                </div>
                                <div className="carousel-item">
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
                                        className="courseimg img-fluid d-block w-100"
                                        rounded
                                        alt="CTET"
                                        style={{ height: '40vh', objectFit: 'cover' }}
                                    />
                                </div>
                                <div className="carousel-item">
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                                        className="courseimg img-fluid d-block w-100"
                                        rounded
                                        alt="CTET"
                                        style={{ height: '40vh', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>

                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>




                </div>
            </div>


            <div className="container mt-4 mb-4">

                <Row className="gs-5 ge-5">
                    <Col md={12} className='text-center mt-4 mb-4'>
                        <h2 style={{ color: 'Black', fontSize: '1.5em' }} className='htwo_size'>How to Enroll?</h2>
                    </Col>

                    <Col md={3} >
                        <Card className="custom-card text-center h-100 ">
                            <div className="red-banner">
                                <h5>Register</h5>
                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text >
                                    <div style={{ fontSize: '16px' }}>
                                        Enter your details and sign up.
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Search Field Card */}
                    <Col md={3} >
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5>Take the Mock Test </h5>
                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <div style={{ fontSize: '16px' }}>
                                        Experience the CUET (UG)-like environment.
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Pagination Card */}
                    <Col md={3}>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5>Get Instant Results </h5>
                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <div style={{ fontSize: '16px' }}>
                                        Receive a detailed performance report.
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>


                    {/* Image Carousel Card */}
                    <Col md={3} className='mt-3 justify-content-center'>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5>Improve & Retake </h5>
                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <div style={{ fontSize: '16px' }}>
                                        Work on your weak areas and try again!
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>

            </div>


            {/* FAQ */}

            <div div className="blog-faq w-100 mt-5" >
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                    <div className='text-center'>
                        <h1 className="hone_size text-dark">FAQs on CUET UG
                        </h1>
                    </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">

                    <Accordion
                        data-responsive-accordion-tabs="-medium-tabs large-tabs"

                        className="w-100"
                    >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                1. What is the CUET (UG) Mock Test?
                            </Accordion.Header>
                            <Accordion.Body>The CUET (UG) Mock Test is a practice exam designed to simulate the actual CUET (UG) exam. It helps students familiarize themselves with the exam pattern, question types, and time management strategies.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                2. Is the CUET (UG) Mock Test exactly like the real exam?
                            </Accordion.Header>
                            <Accordion.Body>
                                Yes, our CUET (UG) Mock Test closely follows the actual CUET (UG) pattern, covering subject-specific questions with the same difficulty level and time constraints.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                3. How can I access the CUET (UG) Mock Test?
                            </Accordion.Header>
                            <Accordion.Body>You can register by providing your name, email, and phone number. Once registered, you’ll receive access to take the mock test and analyze your performance.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                4. Will I get a performance report after completing the test?
                            </Accordion.Header>
                            <Accordion.Body>Yes, after completing the mock test, you’ll receive a detailed performance analysis highlighting your strengths and areas that need improvement.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                5. Why should I take the CUET (UG) Mock Test?
                            </Accordion.Header>
                            <Accordion.Body>Taking the CUET (UG) Mock Test helps you understand the real exam format, improve speed and accuracy, and boost confidence before the actual test day.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </div >

        </>
    )
}
