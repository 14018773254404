import React, { useState } from 'react'
import { Accordion, Card, Col, Image, Row } from 'react-bootstrap'
import wpicon from '../../../Assets/Frame_1.png'
import CUETUGForm from './CUETUGForm';

export default function FreeCounselling() {

    const [show, setShow] = useState(false);

    const defaultMessage = process.env.REACT_APP_MESSAGE;

    return (
        <>
            <div className="fab-wrapper" style={{ zIndex: "999" }}>
                <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
                <label className="fab  d-flex align-items-center justify-content-center text-white" htmlFor="fabCheckbox">
                    {/* <span className="fab-dots fab-dots-2">
              <FaPlus />
            </span> */}
                    <a
                        className=""
                        target="_blank"
                        href={`https://wa.me/${8133836741}?text=${defaultMessage}`}
                    >
                        {/* <FaWhatsapp /> */}
                        <img src={wpicon} alt="icon" className="img-fluid" />
                    </a>

                </label>

            </div>
            <div className="col-md-12 mt-5">
                <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                    <div className="cuetctn ms-0 me-0 me-sm-5 mb-5 ms-lg-5 mb-l">
                        <h1 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='hone_size'> Get Expert CUET Counselling – 100% FREE!</h1>
                        <p style={{ textAlign: "justify", fontSize: '20px' }} className='mt-2 mb-2'>
                            Not sure how to crack CUET? Get expert guidance on subject selection, admission strategies, and more!
                        </p>
                        <p style={{ textAlign: "justify", fontSize: '20px' }} className='mt-2 mb-2'>
                            Secure your future with personalized CUET counselling. Book your FREE session now!
                        </p>
                        <h1 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='hone_size'> Confused About CUET? Get Free Guidance Today!</h1>
                        <p style={{ textAlign: "justify", fontSize: '20px' }} className='mt-2 mb-2'>
                            Choosing the right university and preparing for CUET can be overwhelming. Our FREE CUET Counselling helps you make informed decisions, ensuring you take the right steps toward success. Unlike others, we charge nothing for expert guidance!
                        </p>

                    </div>

                    <Image
                        src={`${process.env.PUBLIC_URL}/assets/online_pg/group-young-indian-students-sitting-auditorium-lecture-row-smiling_538002-3755.jpg`}
                        className="courseimg img-fluid"
                        rounded
                        alt="CTET"
                        style={{ objectFit: 'cover', width: '600px', height: 'auto' }}
                    />

                </div>
            </div>

            <div className="container mt-4">

                <Row className="gs-5 ge-5">
                    <Col md={12} className='text-center mt-4 mb-4'>
                        <h2 className='htwo_size '> Why Choose Our CUET Counselling?</h2>

                    </Col>

                    <Col md={4} >
                        <Card className="custom-card text-center h-100 ">
                            <div className="red-banner">
                                <h5 className="hfive_size" >Absolutely Free Guidance </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text >
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Unlike others, we offer expert CUET counselling at no cost!     </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} >
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">

                                <h5 className="hfive_size" >	One-on-One Expert Sessions </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Get personalized advice tailored to your strengths & career goals. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" > Stream & Subject Selection Help </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Unsure about what to choose? We help you make informed decisions. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} className='mt-2'                   ></Col>
                    <Col md={4} className='mt-2'>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" >University Admission Strategies </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Learn how to maximize your CUET(UG) score for top universities. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className='mt-2'>
                        <Card className="custom-card text-center  h-100">
                            <div className="red-banner">
                                <h5 className="hfive_size" > Comprehensive CUET Insights </h5>

                            </div>
                            <Card.Body className="custom-card-body">
                                <Card.Text>
                                    <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                        Understand exam patterns, weightage, and preparation strategies. </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} className='mt-2'
                    ></Col>
                </Row>
            </div>

            <div className='join_img'>
                <div className="reg_content mb-5">
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-12 d-flex flex-column text-center'>
                            <h1 className='mt-5' style={{ fontSize: '35px', color: '#fff' }}>Book Your Free CUET Counselling Now!</h1>
                            <p className='mt-5 fs-5'>Don't leave your future to chance! Take advantage of our FREE CUET Counselling today.<br></br> Get expert guidance, clear your doubts, and build a winning strategy.</p>
                        </div>
                        <div className='col-md-12  mb-5 mt-5'>
                            <button
                                className="reg_btn btn me-2"
                                type="button"
                                onClick={() => setShow(true)}
                            >
                                Enroll Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offering_container p-2">
                <div className="offering-heading">
                    {/* <h3 className="h3_head">We offer you</h3> */}
                    <h3 className="h3_head">Who Can Benefit from Our CUET Counselling?</h3>
                    <p className="lorem_1" >
                        Our expert counselling is ideal for:
                    </p>
                </div>

                <div className="card-container">
                    <div className="p-2 Course_card mb-3">
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/cardimg/4478ff9024b692b860004cb7aaa1f43f.png`}
                            alt="frame"
                            className="img-course mt-0"
                            fluid
                        />
                        <div className="card-title">
                            <h5
                                className="card-university-title"
                                style={{ color: "#71269C" }}
                            >
                                Class 12 students preparing for CUET(UG).
                            </h5>

                        </div>
                    </div>
                    <div className="p-2 Course_card mb-3">
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/cardimg/432d9280b2bd93e065bf1dcd14dfc11d.png`}
                            alt="frame"
                            className="img-course mt-0"
                            fluid
                        />
                        <div className="card-title">
                            <h5
                                className="card-university-title"
                                style={{ color: "#71269C" }}
                            >
                                Graduates exploring CUET(PG) options.
                            </h5>

                        </div>
                    </div>
                    <div className="p-2 Course_card mb-3">
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/cardimg/c962f8f3520869897b37998bf3560539.png`}
                            alt="frame"
                            className="img-course mt-0"
                            fluid
                        />
                        <div className="card-title">
                            <h5
                                className="card-university-title"
                                style={{ color: "#71269C" }}
                            >
                                Anyone seeking clarity on CUET admissions & university selections.
                            </h5>

                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-faq w-100 mt-5">
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                    <div className='text-center'>
                        <h1 className="hone_size text-dark">Frequently Asked Questions (FAQs)                        </h1>
                    </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">

                    <Accordion
                        data-responsive-accordion-tabs="-medium-tabs large-tabs"

                        className="w-100"
                    >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                1.	What is CUET Counselling, and how can it help me?
                            </Accordion.Header>
                            <Accordion.Body>CUET Counselling is a personalized guidance service to help students understand the CUET(UG) process, choose the right subjects, and maximize their admission chances.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                2.	Is CUET Counselling really free?
                            </Accordion.Header>
                            <Accordion.Body>
                                Yes! Unlike other platforms that charge for counselling, we offer 100% free expert guidance to help you succeed.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                3.	Who can benefit from CUET Counselling?
                            </Accordion.Header>
                            <Accordion.Body>Class 12 students preparing for CUET(UG) and graduates exploring CUET(PG) options can gain valuable insights from our counselling.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                4.	How do I book my free CUET Counselling session?
                            </Accordion.Header>
                            <Accordion.Body>Simply fill in your details on the form, and our experts will reach out to schedule your session.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                            <Accordion.Header className="accordion_header w-100">
                                5.	What will I learn in the CUET Counselling session?
                            </Accordion.Header>
                            <Accordion.Body>You’ll receive insights on CUET exam patterns, subject selection, university admissions, and effective preparation strategies.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </div>
            <CUETUGForm setShow={setShow} show={show} type={"Free-Counselling"} />
        </>
    )
}
